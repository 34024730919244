import { gql } from '@apollo/client'

const QUERY = gql`
    mutation Login($email: String!, $password: String!) {
        login(input: {
            email: $email,
            password: $password,
        })
    }
`

export { QUERY }