import React from "react"

import { ModalLayout } from "../layouts/modal"

import { LoginForm } from "../forms/login" // '../../shared/components/forms/login'

const Page = () => {

    const content = {}
    content.htmlTitle = 'Login'
    content.pageTitle = 'Log in to Yolk'
    content.pageTagline = 'Time for some data work'

    const form = <LoginForm />;

    return (
        <ModalLayout {...content} form={form} />
    )

}

export default Page