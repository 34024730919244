import React from "react";
import * as Yup from "yup";
import { Link } from "gatsby";
import { ApolloProvider } from "@apollo/client"
import { client } from "../../app/provider/apollo/client"

import { QUERY } from "../../queries/login";
import { FormWrapper } from "../wrapper"

const LoginForm = (props) => {

    const schema = {
        email: Yup.string()
            .email("Invalid Email Address")
            .required("*Required"),
        password: Yup.string().required("*Required"),
    };

    const inputFields = {
        email: {
            // label: "Email:"
            placeholder: "Email"
        },
        password: {
            // label: "Password:",
            placeholder: "Password",
            type: "password"
        }
    };

    const sundries = (
        <React.Fragment>
            <div>
                Forgot your password? <Link to="/password/forgot">Reset your password</Link>
            </div>
            {/* <div>
                Don't have an account? <Link to="/signup">Signup</Link>
            </div> */}
        </React.Fragment>
    );

    return (
        <ApolloProvider client={client}>
            <FormWrapper
                ctaText="Login"
                dbQueryName="login"
                successRoute="/a/dashboard"
                submitErrMsg="Please try again."
                inputFields={inputFields}
                schema={schema}
                query={QUERY}
                sundries={sundries}
            />
        </ApolloProvider>
    );
};

export { LoginForm };
export { QUERY }